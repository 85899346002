import { useGetPlan } from '@/components/insight/hooks/useGetPlan';
import { useGetPartnerPlusGnbTooltipText } from '@/components/partner_plus/hooks/useGetPartnerPlusGnbTooltipText';
import { Config } from '@/config';
import { SiteId } from '@/constants/site';
import { useAccount } from '@/hooks/useAccount';
import { useInfo } from '@/hooks/useInfo';

export function useGetPartnerPlusTooltip(enabled?: boolean) {
  const TOOLTIP_KEYS = `PartnerPlusGnb.${Config.shop_main_domain}.Tooltip`;
  const { info } = useInfo();
  const { is_zigzag } = useAccount();
  const tooltip_id = localStorage.getItem(TOOLTIP_KEYS);
  const is_shown = is_zigzag || info.site?.id === SiteId.지그재그;
  const { is_partner_plus } = useGetPlan();
  const { data, isLoading } = useGetPartnerPlusGnbTooltipText(enabled && is_partner_plus && is_shown);
  const is_show = Boolean(is_partner_plus) && is_shown && !isLoading && tooltip_id !== data?.id;

  return {
    text: data?.contents,
    is_show,
    hideTootip() {
      if (data?.id) {
        localStorage.setItem(TOOLTIP_KEYS, data.id);
      }
    },
  };
}
