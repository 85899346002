import { PageHeader, SectionTitle } from '@croquiscom/pds';
import React, { lazy } from 'react';
import { Trans } from 'react-i18next';
import { RoutesType } from '../types';
import { SellerResource } from '@/api';
import { HelpConstantLink } from '@/components/common/HelpConstantLink';
import { CARRY_OUT_STEPS } from '@/components/zigzin/carry_out/constants';
import { ZIGZIN_GUIDE_OPTIONS } from '@/components/zigzin/constants';
import PromotionPageHeader from '@/components/zigzin/fee_promotion/PageHeader';
import ObsoleteStockPageHeader from '@/components/zigzin/fee_promotion/zigzin_outlet/ObsoleteStockPageHeader';
import ZigzinDetailInsightNav from '@/components/zigzin/insight/widgets/ZigzinDetailInsightNav';
import { ZigzinInsightNav } from '@/components/zigzin/insight/widgets/ZigzinInsightNav';
import ZigzinOutletPromotionPopup from '@/components/zigzin/modal/ZigzinOutletPromotionPopup';
import StockIntroNav from '@/components/zigzin/stock/IntroNav';
import StockPageHeader from '@/components/zigzin/stock/PageHeader';
import { CarryOutUrl, StockUrl, WarehousedInUrl } from 'rui/components/URL';
import { PageHeaderStepper } from 'rui/pastel/unstable/page-header';

const ZigzinCarryOut = lazy(() => import('@/pages/zigzin/carry_out/CarryOutPage'));
const ZigzinCarryOutPickItem = lazy(() => import('@/pages/zigzin/carry_out/CarryOutPickItem'));
const ZigzinCarryOutRequestInvoice = lazy(() => import('@/pages/zigzin/carry_out/CarryOutRequestInvoice'));
const ZigzinCarryOutSetOrderer = lazy(() => import('@/pages/zigzin/carry_out/CarryOutSetOrderer'));
const ZigzinStoreAllotmentAmountSettlement = lazy(
  () => import('@/pages/zigzin/store_allotment_amount_settlement/StoreAllotmentAmountSettlement'),
);
const ZigzinFeeSettlement = lazy(() => import('@/pages/zigzin/fee_settlement/FeeSettlementPage'));
const ZigzinInsightToday = lazy(() => import('@/pages/zigzin/insight/InsightTodayPage'));
const ZigzinInsightPerformanceDetail = lazy(() => import('@/pages/zigzin/insight/InsightPerformanceDetailPage'));
const ZigzinInsightPerformance = lazy(() => import('@/pages/zigzin/insight/InsightPerformancePage'));
const ZigzinInsightRecommended = lazy(() => import('@/pages/zigzin/insight/InsightRecommendedPage'));
const ZigzinInsightStockDetail = lazy(() => import('@/pages/zigzin/insight/InsightStockDetailPage'));
const ZigzinInsightStock = lazy(() => import('@/pages/zigzin/insight/InsightStockPage'));
const ZigzinIntro = lazy(() => import('@/pages/zigzin/intro/IntroPage'));
const ZigzinSignUp = lazy(() => import('@/pages/zigzin/sign_up/SignUpPage'));
const ZigzinSignUpStatus = lazy(() => import('@/pages/zigzin/sign_up/SignUpStatusPage'));
const ZigzinOrder = lazy(() => import('@/pages/zigzin/order/OrderPage'));
const ZigzinStock = lazy(() => import('@/pages/zigzin/stock/StockPage'));
const ZigzinStockCarryOut = lazy(() => import('@/pages/zigzin/stock/StockCarryOutPage'));
const ZigzinWarehousedInV2 = lazy(() => import('@/pages/zigzin/warehoused_in/v2/WarehousedInPage'));
const ZigzinWarehousedInRequestV2 = lazy(() => import('@/pages/zigzin/warehoused_in/v2/WarehousedInRequestPage'));
const ZigzinWarehousedInV2BasicInfo = lazy(() => import('@/pages/zigzin/warehoused_in/v2/WarehousedInBasicInfo'));
const ZigzinWarehousedInV2PickItem = lazy(() => import('@/pages/zigzin/warehoused_in/v2/WarehousedInPickItem'));
const ZigzinWarehousedInV2SetOrderer = lazy(() => import('@/pages/zigzin/warehoused_in/v2/WarehousedInSetOrderer'));
const ZigzinWarehousedInV2RequestInvoice = lazy(
  () => import('@/pages/zigzin/warehoused_in/v2/WarehousedInRequestInvoice'),
);
const ZigzinWarehousedInV2ExcelUpload = lazy(() => import('@/pages/zigzin/warehoused_in/v2/WarehousedInExcelUpload'));
const ZigzinWarehousedInV2ExcelUploadSetOrderer = lazy(
  () => import('@/pages/zigzin/warehoused_in/v2/WarehousedInExcelUploadSetOrderer'),
);
const ZigzinWarehousedInExcelUploadRequestInvoice = lazy(
  () => import('@/pages/zigzin/warehoused_in/v2/WarehousedInExcelUploadRequestInvoice'),
);
const ZigzinAdditionalItem = lazy(() => import('@/pages/zigzin/additional_item/AdditionalItemPage'));
const ZigzinOutletPromotionPage = lazy(() => import('@/pages/zigzin/fee_promotion/ZigzinOutletPromotionPage'));
const ZigzinFeeDiscountPage = lazy(() => import('@/pages/zigzin/fee_promotion/ZigzinFeeDiscountPage'));
const ZigzinObsoleteStockPromotionPage = lazy(
  () => import('@/pages/zigzin/fee_promotion/ZigzinObsoleteStockPromotionPage'),
);
const ZigzinNeedMatchingPage = lazy(() => import('@/pages/zigzin/need_matching/NeedMatchingPage'));
const ZigzinPenaltyItemPage = lazy(() => import('@/pages/zigzin/penalty_item/ZigzinPenaltyItemPage'));
const InboundProblemSolvedPage = lazy(() => import('@/pages/zigzin/inbound_problem_solved/InboundProblemSolvedPage'));

export const ZIGZIN_ROUTES: RoutesType[] = [
  {
    current_menu: 'zigzin_intro',
    path: '/zigzin/intro',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='직진배송 소개' />
        </PageHeader>
        <ZigzinIntro />
      </>
    ),
  },
  {
    current_menu: 'zigzin_sign_up',
    path: '/zigzin/sign_up',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='직진배송 신청' />
        </PageHeader>
        <ZigzinSignUp />
      </>
    ),
  },
  {
    current_menu: 'zigzin_sign_up_status',
    path: '/zigzin/sign_up_status',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle title='직진배송 신청 현황' />
        </PageHeader>
        <ZigzinSignUpStatus />
      </>
    ),
    res: SellerResource.ALL,
  },
  {
    current_menu: 'zigzin_insight',
    path: '/zigzin/insight/performance/detail',
    params: '/:productId',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='직진배송 상품별 인사이트' />
        </PageHeader>
        <ZigzinDetailInsightNav tab='performance' />
        <ZigzinInsightPerformanceDetail />
      </>
    ),
    res: SellerResource.ZONLY_INSIGHT,
  },
  {
    current_menu: 'zigzin_insight',
    path: WarehousedInUrl.INSIGHT_TODAY,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='직진배송 인사이트' />
        </PageHeader>
        <ZigzinInsightNav tab='today' />
        <ZigzinInsightToday />
      </>
    ),
    res: SellerResource.ZONLY_INSIGHT,
  },
  {
    current_menu: 'zigzin_insight',
    path: '/zigzin/insight/performance',
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='직진배송 인사이트' />
        </PageHeader>
        <ZigzinInsightNav tab='result' />
        <ZigzinInsightPerformance />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_INSIGHT,
  },
  {
    current_menu: 'zigzin_insight',
    path: '/zigzin/insight/stock/detail',
    params: '/:productId',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='직진배송 상품별 인사이트' />
        </PageHeader>
        <ZigzinDetailInsightNav tab='stock' />
        <ZigzinInsightStockDetail />
      </>
    ),
    res: SellerResource.ZONLY_INSIGHT,
  },
  {
    current_menu: 'zigzin_insight',
    path: '/zigzin/insight/stock',
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='직진배송 인사이트' />
        </PageHeader>
        <ZigzinInsightNav tab='stock' />
        <ZigzinInsightStock />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_INSIGHT,
  },
  {
    current_menu: 'zigzin_insight',
    path: '/zigzin/insight/recommended',
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='직진배송 인사이트' />
        </PageHeader>
        <ZigzinInsightNav tab='recommended' />
        <ZigzinInsightRecommended />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_INSIGHT,
  },
  {
    current_menu: 'zigzin_penalty_item',
    path: '/zigzin/penalty_item',
    params: '',
    component: <ZigzinPenaltyItemPage />,
  },
  {
    current_menu: 'zigzin_order',
    path: '/zigzin/order',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='직진배송 주문 검색'
            description={
              <>
                - [직진배송] 로 주문한 고객들의 주문 내역을 조회할 수 있습니다.
                <br />- [직진배송] 주문에 대한 배송, 취소, 반품, 교환 관리는 지그재그에서 진행합니다.
                <br />- 고객에게 지그재그 고객센터 안내 시, 반드시 고객 전용 채널인 1670-8050 으로 안내해주세요.
              </>
            }
          />
        </PageHeader>
        <ZigzinOrder />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_READ,
  },
  {
    current_menu: 'zigzin_stock',
    path: StockUrl.STOCK,
    params: '',
    component: (
      <>
        <StockPageHeader />
        <StockIntroNav current_tab='stock' />
        <ZigzinStock />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_READ,
  },
  {
    current_menu: 'zigzin_stock',
    path: StockUrl.CARRY_OUT,
    params: '',
    component: (
      <>
        <StockPageHeader />
        <StockIntroNav current_tab='carry_out' />
        <ZigzinStockCarryOut />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_READ,
  },
  {
    current_menu: 'zigzin_carry_out',
    path: CarryOutUrl.PICK_ITEM,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='반출 요청하기' description='직진배송 상품의 반출 신청서를 작성할 수 있습니다.' />
        </PageHeader>
        <PageHeaderStepper steps={CARRY_OUT_STEPS} active_index={0} />
        <ZigzinCarryOutPickItem />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_carry_out',
    path: CarryOutUrl.SET_ORDERER,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='반출 요청하기' description='직진배송 상품의 반출 신청서를 작성할 수 있습니다.' />
        </PageHeader>
        <PageHeaderStepper steps={CARRY_OUT_STEPS} active_index={1} />
        <ZigzinCarryOutSetOrderer />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_carry_out',
    path: CarryOutUrl.REQUEST_INVOICE,
    params: '',
    component: (
      <>
        <PageHeader border={false}>
          <SectionTitle title='반출 요청하기' description='직진배송 상품의 반출 신청서를 작성할 수 있습니다.' />
        </PageHeader>
        <PageHeaderStepper steps={CARRY_OUT_STEPS} active_index={2} />
        <ZigzinCarryOutRequestInvoice />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_carry_out',
    path: CarryOutUrl.LIST,
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='반출 관리'
            description={
              <>
                - 창고에 입고된 직진배송 상품을 반출 요청 및 반출 현황을 확인할 수 있습니다.
                <br />- 반출 신청 시 반출 희망일은 매주 화요일,목요일만 선택가능합니다.
                <br />- 직진배송 반출 관련 문의가 있으실 경우, 오른쪽 하단 채팅 상담으로 문의주세요.{' '}
                <HelpConstantLink options={ZIGZIN_GUIDE_OPTIONS} linkKey='운영 가이드'>
                  직진배송 운영 가이드
                </HelpConstantLink>
              </>
            }
          />
        </PageHeader>
        <ZigzinCarryOut />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_READ,
  },
  {
    current_menu: 'zigzin_fee_settlement',
    path: '/zigzin/fee_settlement',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='이용료 내역'
            description={
              <>
                - 직진배송 물류 이용료 내역과 청구 금액을 확인할 수 있습니다.
                <br />- 직진배송 이용료 관련 문의가 있으실 경우, 오른쪽 하단 채팅 상담으로 문의주세요.
              </>
            }
          />
        </PageHeader>
        <ZigzinFeeSettlement />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_FEE_HISTORY,
  },
  {
    current_menu: 'zigzin_store_allotment_amount_settlement',
    path: '/zigzin/store_allotment_amount_settlement',
    params: '',
    component: (
      <>
        <PageHeader>
          <SectionTitle
            title='직진배송 스토어 부담 금액 정산 상세'
            description={
              <Trans ns='zigzin'>
                - 스토어 쿠폰/프로모션/마일리지의 정산내역은 주문번호로 조회할 수 있습니다.
                <br />- 쿠폰/프로모션/마일리지 상태에 따라 스토어 부담 금액에 대한 공제 및 환급이 발생합니다.
              </Trans>
            }
          />
        </PageHeader>
        <ZigzinStoreAllotmentAmountSettlement />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_COUPON_SETTLEMENT,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2',
    path: WarehousedInUrl.LIST_V2,
    params: '',
    is_support_mobile: true,
    component: (
      <>
        <ZigzinWarehousedInV2 />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_READ,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.REQUEST_LIST_V2,
    params: '',
    is_support_mobile: true,
    component: (
      <>
        <ZigzinWarehousedInRequestV2 />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_READ,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.BASIC_INFO,
    params: '',
    is_support_mobile: true,
    component: <ZigzinWarehousedInV2BasicInfo />,
    res: SellerResource.ZONLY_MANAGEMENT_READ,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.PICK_ITEM_V2,
    params: '',
    is_support_mobile: true,
    component: <ZigzinWarehousedInV2PickItem />,
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.SET_ORDERER_V2,
    params: '',
    is_support_mobile: true,
    component: <ZigzinWarehousedInV2SetOrderer />,
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.REQUEST_INVOICE_V2,
    params: '',
    is_support_mobile: true,
    component: <ZigzinWarehousedInV2RequestInvoice />,
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.REQUEST_INVOICE_V2,
    params: '',
    component: <ZigzinWarehousedInV2RequestInvoice />,
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.EXCEL_UPLOAD,
    params: '',
    component: <ZigzinWarehousedInV2ExcelUpload />,
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.EXCEL_UPLOAD_SET_ORDERER_V2,
    params: '',
    is_support_mobile: true,
    component: <ZigzinWarehousedInV2ExcelUploadSetOrderer />,
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_warehoused_in_v2_request',
    path: WarehousedInUrl.EXCEL_UPLOAD_REQUEST_INVOICE_V2,
    params: '',
    is_support_mobile: true,
    component: <ZigzinWarehousedInExcelUploadRequestInvoice />,
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_additional_item',
    path: WarehousedInUrl.ADDITIONAL_ITEM,
    params: '',
    component: (
      <>
        <ZigzinAdditionalItem />
        <ZigzinOutletPromotionPopup />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_fee_promotion',
    path: WarehousedInUrl.ZIGZIN_OUTLET_PROMOTION,
    params: '',
    component: (
      <>
        <PromotionPageHeader current_tab='zigzin_outlet' />
        <ZigzinOutletPromotionPage />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_fee_promotion',
    path: WarehousedInUrl.ZIGZIN_FEE_DISCOUNT,
    params: '',
    component: (
      <>
        <PromotionPageHeader current_tab='zigzin_fee_discount' />
        <ZigzinFeeDiscountPage />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_fee_promotion',
    path: WarehousedInUrl.ZIGZIN_OBSOLETE_STOCK_PROMOTION,
    params: '',
    component: (
      <>
        <ObsoleteStockPageHeader />
        <ZigzinObsoleteStockPromotionPage />
      </>
    ),
    res: SellerResource.ZONLY_MANAGEMENT_WRITE,
  },
  {
    current_menu: 'zigzin_penalty_item',
    path: '/zigzin/need_matching',
    params: '',
    component: <ZigzinNeedMatchingPage />,
  },
  {
    current_menu: 'zigzin_inbound_problem_solved',
    path: WarehousedInUrl.INBOUND_PROBLEM_SOLVED,
    params: '',
    component: (
      <>
        <InboundProblemSolvedPage />
      </>
    ),
  },
];
